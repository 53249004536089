import React from 'react'
import PropTypes from 'prop-types'
import ErrorBoundary from 'commons/js/components/ErrorBoundary'
import { RemoteConfigConsumer, Box, PromoBanner } from '@elparking/components'

const Banner = ({ value, id }) => (
    <ErrorBoundary>
        <RemoteConfigConsumer value={value}>
            {({ active = false,
                text,
                link_text: linkText,
                link_url: linkUrl,
                enable_on_mobile: showOnMobile,
                campaign,
            }) => (
                active && <Box w='100%'>
                    <PromoBanner
                      text={text}
                      linkText={linkText}
                      linkUrl={linkUrl}
                      showOnMobile={showOnMobile}
                      campaign={campaign}
                      id={id}
                    />
                </Box>
            )}
        </RemoteConfigConsumer>
    </ErrorBoundary>
)

Banner.propTypes = {
    value: PropTypes.string,
    id: PropTypes.string,
}

export default Banner
