import App from './App'
import render from 'commons/js/util/render'
import { init as initReporter } from 'commons/js/util/reporter'

initReporter()

const element = document.querySelector('.js-banner')
const dataset = element.dataset

const props = {
    value: dataset.value,
    id: dataset.id,
}

render({ App, element, props })
