import { hot } from 'react-hot-loader/root'
import React from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import { EPProvider, PromoBannerProvider } from '@elparking/components'
import convertConstantToEPProviderFormat from 'commons/js/util/coverterGlobalConfig'
import { messages } from 'commons/js/messages'
import { defaultLocale, currentLocale } from 'commons/js/locale'

import theme from './theme'
import Banner from './components/Banner'

const App = ({ value, id }) => (
    <IntlProvider defaultLocale={defaultLocale} locale={currentLocale} messages={messages[currentLocale]}>
        <EPProvider theme={theme} {...convertConstantToEPProviderFormat()}>
            <PromoBannerProvider>
                <Banner value={value} id={id} />
            </PromoBannerProvider>
        </EPProvider>
    </IntlProvider>
)

App.propTypes = {
    value: PropTypes.string,
    id: PropTypes.string,
}

export default hot(App)
